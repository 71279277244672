define([
    'santa-components',
    'lodash',
    'santa-core-utils',
    'tpaComponents/utils/tpaUtils',
    'tpaComponents/common/TPAUrlBuilder'
],
function (
    santaComponents,
    _,
    coreUtilsLib,
    tpaUtils,
    TPAUrlBuilder
) {
    'use strict';
    const BOOKING_APP_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';

    const getIframeQueryParams = function (comp, queryParamsObj) {
        if (comp.mutateIframeUrlQueryParam) {
            queryParamsObj = comp.mutateIframeUrlQueryParam(queryParamsObj);
        }

        return queryParamsObj;
    };

    const resolveTemplateUrlParams = (baseUrl, appData, compId) => {
        let resolvedBaseUrl = baseUrl;

        if (_.includes(baseUrl, '{instanceId}')) {
            let instanceId = appData.instanceId;
            if (!instanceId) {
                const instance = tpaUtils.getInstanceFromAppData(appData);
                instanceId = instance.instanceId;
            }

            resolvedBaseUrl = _.replace(resolvedBaseUrl, new RegExp('{instanceId}', 'g'), instanceId);
        }

        if (_.includes(baseUrl, '{compId}')) {
            resolvedBaseUrl = _.replace(resolvedBaseUrl, new RegExp('{compId}', 'g'), compId);
        }

        return resolvedBaseUrl;
    };

    function shouldTemplateValue() {
        return this.props.isInSSR && this.props.isCacheable;
    }

    function getFullUrl(url, hasQueryParams) {
        const {applicationId, widgetId} = this.props.compData;
        if (!url || !shouldTemplateValue.call(this) || _.isNil(applicationId)) {
            return url;
        }

        const baseUrl = `${_.head(_.split(url, '?'))}?`;
        const queryConnector = hasQueryParams ? '&' : '?';
        const templateBaseUrl = _.get(this, 'isTPAWorker', _.noop)() ? `\${${applicationId}.appWorkerUrl}${queryConnector}` : `\${${applicationId}.widgets.${widgetId}.widgetUrl}${queryConnector}`;
        return _.replace(url, baseUrl, templateBaseUrl);
    }

    function getMultipleQueryParams() {
        let params = _.clone(_.get(this.props, 'aspectData.queryParams', {}));
        params = _.merge(params, getIframeQueryParams(this, params));
        params = _.merge(params, tpaUtils.getDebuggingParamsFromUrl(this.props.currentUrl));
        params = _.merge(params, this.state.sectionUrlParams);

        // is linked to felix extension called tpa params,
        // should deprecate this and they should use the AppSectionParams!!
        if (this.getEcomParams && this.getEcomParams()) {
            params['ecom-tpa-params'] = this.getEcomParams();
        }
        return params;
    }

    function getInstance() { // eslint-disable-line complexity
        if (shouldTemplateValue.call(this)) {
            return `\${${this.props.compData.applicationId}.instance}`;
        }
        const isBookingOnTemplate = this.props.isExperimentOpen('sv_pass_booking_initial_instance_before_save') && this.props.isPreviewMode && this.props.isTemplate && this.props.compData.appDefinitionId === BOOKING_APP_DEF_ID;
        if (isBookingOnTemplate) {
            if (this.props.initialClientSpecMap && this.props.initialClientSpecMap[this.props.compData.applicationId]) {
                return this.props.initialClientSpecMap[this.props.compData.applicationId].instance;
            }
        }
        return this.state.initialInstance;
    }

    function getExternalId() {
        return this.state.preventRefresh && this.props.isExperimentOpen('sv_tpaExternalIdPreventRefresh', null) ? this.state.externalId : this.props.compData.referenceId;
    }
    /**
         * @class tpa.mixins.tpaUrlBuilder
         */
    return {
        propTypes: {
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            isViewerMode: santaComponents.santaTypesDefinitions.isViewerMode,
            isGoogleBot: santaComponents.santaTypesDefinitions.isGoogleBot,
            componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode,
            languageCode: santaComponents.santaTypesDefinitions.RendererModel.languageCode,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            rootId: santaComponents.santaTypesDefinitions.Component.rootId,
            viewerSessionId: santaComponents.santaTypesDefinitions.viewerSessionId,
            currentUrl: santaComponents.santaTypesDefinitions.currentUrl.isRequired,
            aspectData: santaComponents.santaTypesDefinitions.TPA.data,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            currentLanguage: santaComponents.santaTypesDefinitions.RendererModel.multilingual.currentLanguage,
            originalLanguage: santaComponents.santaTypesDefinitions.RendererModel.multilingual.originalLanguage,
            currency: santaComponents.santaTypesDefinitions.RendererModel.currency,
            currentCurrency: santaComponents.santaTypesDefinitions.RendererModel.multiCurrency.currentCurrency,
            timeZone: santaComponents.santaTypesDefinitions.RendererModel.timeZone,
            isInSSR: santaComponents.santaTypesDefinitions.isInSSR,
            isCacheable: santaComponents.santaTypesDefinitions.isCacheable,
            siteRevision: santaComponents.santaTypesDefinitions.PublicModel.siteRevision,
            isTemplate: santaComponents.santaTypesDefinitions.isTemplate,
            isPreviewMode: santaComponents.santaTypesDefinitions.isPreviewMode,
            initialClientSpecMap: santaComponents.santaTypesDefinitions.TPA.initialClientSpecMap
        },
        getInitialState() {
            this.viewMode = this.getViewMode();
        },

        getViewMode() {
            return this.props.isViewerMode ? 'site' : this.props.componentViewMode;
        },

        buildUrl(baseUrl, whiteList) { // eslint-disable-line complexity
            const hasQueryParams = (/[?]/).test(baseUrl);
            baseUrl = tpaUtils.appendProtocolToUrlIfNeeded(baseUrl, this.props.currentUrl);

            const appData = this.getAppData(this);
            const compId = this.props.id;
            //master component in responsive need the master compId as well
            const editorCompId = coreUtilsLib.displayedOnlyStructureUtil.isReferredId(compId) ?
                coreUtilsLib.displayedOnlyStructureUtil.getReferredCompId(compId) :
                compId;
            baseUrl = resolveTemplateUrlParams(baseUrl, appData, compId);

            const height = this.state.initialHeight ? this.state.initialHeight.toString() : undefined;

            const currentLanguageCode = _.get(this.props, 'currentLanguage.languageCode');
            const originalLanguageCode = this.props.isViewerMode ?
                _.get(this.props, 'originalLanguage.languageCode') :
                _.get(this.props, 'originalLanguage.code');
            const dateNumberFormat = _.get(this.props, 'currentLanguage.locale');
            let isPrimaryLanguage;
            if (currentLanguageCode && originalLanguageCode) {
                isPrimaryLanguage = originalLanguageCode === currentLanguageCode;
            }
            const consentPolicy = this.props.isExperimentOpen('bv_consentPolicy') && '{{consentPolicy}}';

            const urlObj = new TPAUrlBuilder(baseUrl);
            urlObj.addCompId(compId)
                .addEditorCompId(editorCompId)
                .addDeviceType(this.getDeviceType(this))
                .addInstance(getInstance.call(this))
                .addLocale(this.props.languageCode)
                .addViewMode(this.viewMode)
                .addCacheKiller(tpaUtils.getCacheKiller())
                .addExternalId(getExternalId.call(this))
                .addPageId(this.props.rootId)
                .addHeight(height)
                .filterQueryParams(whiteList)
                .addMultipleQueryParams(getMultipleQueryParams.call(this))
                .addViewerSessionId(this.props.viewerSessionId)
                .addRouterData(this.state.initialRouterData, this.props.isExperimentOpen)
                .addLang(currentLanguageCode)
                .addDateNumberFormat(dateNumberFormat)
                .addIsPrimaryLanguage(_.toString(isPrimaryLanguage))
                .addCurrency(this.props.currency)
                .addTimeZone(this.props.timeZone)
                .addSiteRevision(this.props.siteRevision)
                .addConsentPolicy(consentPolicy)
                .addCurrentCurrency(this.props.currentCurrency);


            if (this.mutateIframeSrc) {
                urlObj.mutateIframeSrc(this.mutateIframeSrc);
            }

            return getFullUrl.call(this, urlObj.build(), hasQueryParams);
        }
    };
});
